import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import "./Package.scss";
import Navbar from "../navbar";
import Footer from "../footer";
import localizationStrings from "../../statics/localization";
import mainLogo from "../../assets/logo/filbox-logo.png";
import PackageContent from "../../components/packageContent/PackageContent";
import PopupCloseIcon from "../../assets/icons/svg/PopupCloseIcon";
import Logo from "../../assets/images/multi-screen.png";
import AngleRightPurple from "../../assets/icons/svg/AngleRightPurple";
import { getAllPackages, getSubscription } from "../../api/login/loginApi";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Package = () => {
  const [packageSelected, setPackageSelected] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(false);
  const [packages, setPackages] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const history = useHistory();
  const sliderRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(3);
  const [slideToShowTemp, setSlideToShowTemp] = useState(3);
  var settings = {
    speed: 500,
    slidesToShow: slideToShowTemp,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    draggable: false,
    lazyLoad: false
  };

  useEffect(() => {
    handleSlideChange();
  }, [packages]);

  useEffect(() => {
    function isMobileDevice() {
      return /Mobi|Android/i.test(navigator.userAgent);
    }

    if (isMobileDevice()) {
      setSlideToShowTemp(1);
    }
    getUserSubscription();
    handleSlideChange();
    getPackages();
  }, []);

  const OnPackageSelection = (item) => {
    setPackageSelected(true);
    setSelectedPackage(item);
  };

  const handleSlideChange = () => {
    var slideLimit =
      sliderRef.current.innerSlider.state.slideCount -
      sliderRef.current.props.slidesToShow;
    var slideCurrent = sliderRef.current.innerSlider.state.currentSlide;
    const nextButton = document.querySelector(".slick-button.next");
    const prevButton = document.querySelector(".slick-button.prev");
    if (slideCurrent === slideLimit && packages.length !== 3) {
      nextButton.style.opacity = 0;
      prevButton.style.opacity = 1;
    } else if (packages.length === 3) {
      nextButton.style.opacity = 0;
      prevButton.style.opacity = 0;
    } else if (slideCurrent === 0) {
      prevButton.style.opacity = 0;
      nextButton.style.opacity = 1;
    } else {
      nextButton.style.opacity = 1;
      prevButton.style.opacity = 1;
    }
  };

  const getPackages = async () => {
    const { data } = await getAllPackages();
    if (data.length < currentSlide) {
      setCurrentSlide(data.length);
    }
    if (data?.length && data[0]) {
      const filteredData = data.filter(
        (item) => item.id !== 18 && item.id !== 11
      );
      setPackages(filteredData);
    }
  };

  const getUserSubscription = async () => {
    const { data } = await getSubscription();
    if (data?.length) {
      setCurrentUser(data);
    }
  };

  const renderedPackage = packages.map((item) => {
    return (
      <PackageContent
        key={item.id}
        item={item}
        currentUserPackage={currentUser}
        count={packages.length}
        select={OnPackageSelection}
      />
    );
  });
  if(renderedPackage.length > 0){
    const sortedPackage = renderedPackage.sort((a, b) => 
      (b.props?.item.name === currentUser[0]?.productP.name) - (a.props?.item.name === currentUser[0]?.productP.name)
    );
  }

  return (
    <div className="package-container">
      <div className="package-wrapper">
        <img
          src={mainLogo}
          alt="tivibu logo"
          style={{ maxWidth: "150px", maxHeight: "66px", height: "100px" }}
        />
        {currentUser.length > 0 ? (
          <div
            className="package-close"
            onClick={() => {
              let free = localStorage.getItem("logintype");
              if (free === "free") {
                history.push("/dashboard/livetv");
              } else {
                history.push("/selectprofile");
              }
            }}
          >
            <PopupCloseIcon />
          </div>
        ) : null}
      </div>
      <div className="text">{localizationStrings.pickPackage}</div>
      <div className="mini-text">{localizationStrings.pickPackageMassage}</div>
      {!packageSelected ? (
        <div className="package-item-list">
          <div
            className="slick-button prev"
            onClick={() => sliderRef?.current?.slickPrev()}
            style={{ transform: "rotateY(180deg)" }}
          >
            <span>
              <AngleRightPurple />
            </span>
          </div>
          <Slider
            {...settings}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
              maxWidth: "80%"
            }}
            ref={sliderRef}
            key={Date.now()}
            afterChange={handleSlideChange}
          >
            {renderedPackage}
          </Slider>
          <div
            className="slick-button next"
            onClick={() => sliderRef?.current?.slickNext()}
          >
            <span>
              <AngleRightPurple />
            </span>
          </div>
        </div>
      ) : (
        <div className="purchase-wrapper">
          <div className="purchase-text-main" style={{ opacity: 1 }}>
            SATIN ALMA İŞLEMLERİ
          </div>
          <div className="purchase-info">
            <div className="purchase-entry">
              <div className="purchase-text" style={{ color: "white" }}>
                Üyelik Bilgilerinizi Girin
              </div>
              <input className="purchase-input" placeholder="TCKN" />
              <input className="purchase-input" placeholder="Cep Telefonu" />
              <input className="purchase-input" placeholder="E-Posta" />
              <div className="checkbox-container">
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="agreement"
                    className="checkbox-input"
                  />
                  <label htmlFor="agreement" className="checkbox-label">
                    <span class="underline">
                      Mesafeli Satış Sözleşmesi ve Ön Bilgilendirme Formu
                    </span>
                    <span class="no-underline"> okudum, kabul ediyorum.</span>
                  </label>
                </div>
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="agreement1"
                    className="checkbox-input"
                  />
                  <label htmlFor="agreement1" className="checkbox-label">
                    <span class="underline">
                      Son Kullanıcı Lisans Sözleşmesi
                    </span>
                    <span class="no-underline">ni okudum, kabul ediyorum.</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="purchase-package">
              <img className="purchase-icon" src={Logo} />
              <div className="purchase-info-container">
                <div className="package-info" style={{ marginRight: "25px" }}>
                  <div className="purchase-name">{selectedPackage.info}</div>
                  <div className="purchase-desc">
                    {selectedPackage.description}
                  </div>
                </div>
                <div
                  className="purchase-change"
                  onClick={() => {
                    setPackageSelected(false);
                  }}
                >
                  Paket Değiştir
                </div>
              </div>
              <div className="purchase-info-desc">
                <div className="purchase-button">{selectedPackage.name}</div>
                <div className="purchase-change">
                  <span class="purchase-price" style={{ color: "#F07A22" }}>
                    7,90TL
                  </span>
                  <span
                    class="purchase-price-type"
                    style={{ color: "#F07A22", fontSize: "10px" }}
                  >
                    /ay
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="purchase-text">İndirim / Promosyon Kodu</div>
      <input className="purchase-input" placeholder="Kod" />

      <button className="purple-button" onClick={() => console.log("zort")}>
        Uygula
      </button>
    </div>
  );
};
export default Package;
